<template>
  <div class="influencer-page">
    <CustomPageHeader
      title="Influencer"
      :subtitle="subTitle"
      :hasExport="false"
      sortType="influencer"
      @toggleExport="toggleExport"
    >
    </CustomPageHeader>
    <div v-if="isLoading" class="text-center pa-4">
      <a-spin />
    </div>
    <div v-else class="page-content" @scroll="onScroll">
      <a-row class="section-wrapper">
        <div class="section-title">Type of Influencers</div>
        <div v-if="!isMobileScreen" class="filter-div">
          <div id="sort-selector" class="influencer-filter sort-by">
            <div class="filter-aspect">Measurement by:</div>
            <div class="type-influencer-container">
              <a-select
                ref="select-sort"
                v-model:value="measurementBy"
                class="item-influencer item-sort pd-select"
                :getPopupContainer="(trigger) => trigger.parentNode"
                @focus="focus"
                @change="onSortChange"
              >
                <a-select-option
                  v-for="item in selectItems"
                  :key="item.key"
                  :value="item.value"
                >
                  {{ item.key }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div id="count-selector" class="influencer-filter">
            <div class="filter-aspect">Show:</div>
            <div class="type-influencer-container">
              <a-select
                ref="select-count"
                v-model:value="limitCount"
                class="item-influencer item-show pd-select"
                :getPopupContainer="(trigger) => trigger.parentNode"
                @focus="focus"
                @change="onShowChange"
              >
                <a-select-option :value="10">Top 10</a-select-option>
                <a-select-option :value="20">Top 20</a-select-option>
                <a-select-option :value="50">Top 50</a-select-option>
                <a-select-option :value="100">Top 100</a-select-option>
              </a-select>
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex-align-center mt-2 w-100"
          style="column-gap: 8px"
        >
          <div
            id="sort-selector"
            class="flex-align-center mobile"
            style="flex: 1 1 50%"
          >
            <a-select
              ref="select-sort"
              v-model:value="measurementBy"
              class="item-influencer item-sort pd-select"
              :getPopupContainer="(trigger) => trigger.parentNode"
              @focus="focus"
              @change="onSortChange"
            >
              <a-select-option
                v-for="item in selectList"
                :key="item.key"
                :value="item.value"
              >
                {{ item.key }}
              </a-select-option>
            </a-select>
          </div>
          <div
            id="count-selector"
            class="flex-align-center mobile"
            style="flex: 1 1 50%"
          >
            <a-select
              ref="select-count"
              v-model:value="limitCount"
              class="item-influencer item-show pd-select"
              :getPopupContainer="(trigger) => trigger.parentNode"
              @focus="focus"
              @change="onShowChange"
            >
              <a-select-option :value="10">Top 10</a-select-option>
              <a-select-option :value="20">Top 20</a-select-option>
              <a-select-option :value="50">Top 50</a-select-option>
              <a-select-option :value="100">Top 100</a-select-option>
            </a-select>
          </div>
        </div>
      </a-row>
      <IndustryAndInterest
        :loading="influencerChartLoading"
        :interestChartData="interestChartData"
        :filter="filterCriteria"
      ></IndustryAndInterest>
      <a-row :gutter="25" class="type-card">
        <a-col :xxl="8" :xl="10" :lg="12" :xs="24" class="chart-column-card">
          <div class="chart-wrapper">
            <TypeInfluencer
              :title="'Type'"
              :chartType="'type'"
              :influencerChartData="typeChartData"
              :filter="filterCriteria"
              :loading="influencerChartLoading"
            ></TypeInfluencer>
          </div>
        </a-col>
        <a-col :xxl="8" :xl="10" :lg="12" :xs="24" class="chart-column-card">
          <div class="chart-wrapper">
            <TypeInfluencer
              :title="'Audience Size'"
              :chartType="'audience_size'"
              :influencerChartData="audienceChartData"
              :filter="filterCriteria"
              :loading="influencerChartLoading"
            ></TypeInfluencer>
          </div>
        </a-col>
        <a-col
          :xxl="8"
          :xl="10"
          :lg="12"
          :xs="24"
          class="chart-column-card account-column-card"
        >
          <div class="chart-wrapper">
            <AccountGroupInfluencer
              class="card-account-group"
              :chartData="accountGroupChartData"
              :filter="filterCriteria"
              :loading="influencerChartLoading"
            ></AccountGroupInfluencer>
          </div>
        </a-col>
      </a-row>
      <FilterTopInfluencer
        :chartData="influencersData"
        :loading="topInfluencerLoading"
        :filter="filterCriteria"
        :typeFilter="filterTypeInfluencer"
        :sortBy="measurementBy"
        @onViews="onViewsChange"
        @setLoading="setLoading"
      ></FilterTopInfluencer>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';
import { notification } from 'ant-design-vue';
import CustomPageHeader from '@/components/Layout/CustomPageHeader.vue';
// import InfluencerNetwork from '@/views/Influencer/InfluencerNetwork.vue';
import IndustryAndInterest from '@/views/Influencer/Analysis/IndustryAndInterest.vue';
import TypeInfluencer from '@/views/Influencer/Analysis/TypeInfluencer.vue';
import AccountGroupInfluencer from '@/views/Influencer/Analysis/AccountGroupInfluencer.vue';
import FilterTopInfluencer from '@/views/Influencer/FilterTopInfluencer.vue';
export default {
  components: {
    CustomPageHeader,
    // InfluencerNetwork,
    IndustryAndInterest,
    TypeInfluencer,
    AccountGroupInfluencer,
    FilterTopInfluencer,
  },
  setup() {
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const filterCriteria = computed(
      () => store.getters['filter/filterCriteria']
    );
    const activeKey = ref('1');
    const topInfluencerLoading = ref(true);
    const influencerChartLoading = ref(true);
    const measurementBy = ref('engagement');
    const limitCount = ref(20);
    const interestChartData = ref({});
    const typeChartData = ref([]);
    const audienceChartData = ref([]);
    const accountGroupChartData = ref([]);
    const filterTypeInfluencer = ref([]);
    const influencersData = ref({
      influencers: [],
      peopleInfo: [],
    });
    const selectViews = ref('grid');
    const selectGrid = [
      {
        key: 'engagement',
        value: 'engagement',
      },
      {
        key: 'mentioned',
        value: 'count',
      },
      {
        key: 'view',
        value: 'view_count',
      },
      {
        key: 'Accounts',
        value: 'accounts',
      },
    ];

    const selectList = [
      {
        key: 'engagement',
        value: 'engagement',
      },
      {
        key: 'mentioned',
        value: 'count',
      },
      {
        key: 'Like/Reaction count',
        value: 'like_reaction_count',
      },
      {
        key: 'Comment count',
        value: 'comment_count',
      },
      {
        key: 'Share count',
        value: 'share_count',
      },
      {
        key: 'View count',
        value: 'view_count',
      },
      {
        key: 'Accounts',
        value: 'accounts',
      },
    ];
    let selectItems = ref([]);
    const toggleExport = () => {
      // TODO implement
      console.log('COMING SOON');
    };

    const setActiveKey = (value) => {
      activeKey.value = value;
    };

    const getInfluencerGraph = async (criteria) => {

      let result = await api
        .getInfluencerByMode(measurementBy.value, criteria)
        .catch(() => {
          notification.error({
            message: 'Error',
            description: 'Cannot get influencer data.',
          });
        });

      if (
        result &&
        result.message != null &&
        result.message.influencerInterestAndIndustry != null &&
        result.message.influencers != null &&
        result.message.peopleInfo != null &&
        result.message.influencerType != null &&
        result.message.influencerAudienceSize != null &&
        result.message.influencerAccountGroup != null
      ) {
        interestChartData.value = {
          chartData: result.message.influencerInterestAndIndustry,
          relatedInfluencerCount:
            result.message.influencerCountInInterestAndIndustry || 0,
          influencers: result.message.influencers,
          peopleInfo: result.message.peopleInfo,
        };
        typeChartData.value = {
          chartData: result.message.influencerType,
          relatedInfluencerCount: result.message.influencerCountInType || 0,
          influencers: result.message.influencers,
          peopleInfo: result.message.peopleInfo,
        };
        audienceChartData.value = {
          chartData: result.message.influencerAudienceSize,
          relatedInfluencerCount:
            result.message.influencerCountInAudienceSize || 0,
          influencers: result.message.influencers,
          peopleInfo: result.message.peopleInfo,
        };
        accountGroupChartData.value = {
          chartData: result.message.influencerAccountGroup,
          relatedInfluencerCount: result.message.influencerCountInGroup || 0,
          influencers: result.message.influencers,
          peopleInfo: result.message.peopleInfo,
        };
        influencersData.value = {
          influencers: result.message.influencers,
          peopleInfo: result.message.peopleInfo,
        };
      }

      getTypeInfluencer();


      if (measurementBy.value === 'accounts') {
        influencersData.value = { influencers: [], peopleInfo: [] };
      }

      topInfluencerLoading.value = false;
      influencerChartLoading.value = false;

      if (selectViews.value === 'grid') {
        selectItems.value = selectGrid;
      } else {
        selectItems.value = selectList;
      }
    };

    const getTypeInfluencer = () => {
      for (let type of interestChartData.value.chartData) {
        filterTypeInfluencer.value.push(type.categoryId);
      }
    };

    const init = async () => {
      const finalCriteria = {
        ...filterCriteria.value,
        nodeLimit: limitCount.value,
      };
      await getInfluencerGraph(finalCriteria);
    };

    const onSortChange = async (value) => {
      if (value) {
        measurementBy.value = value;
        influencerChartLoading.value = true;
        topInfluencerLoading.value = true;
        filterTypeInfluencer.value = [];
        const finalCriteria = {
          ...filterCriteria.value,
          nodeLimit: limitCount.value,
        };
        await getInfluencerGraph(finalCriteria);
      }
    };

    const onShowChange = async (value) => {
      if (value) {
        limitCount.value = value;
        influencerChartLoading.value = true;
        topInfluencerLoading.value = true;
        filterTypeInfluencer.value = [];
        const finalCriteria = {
          ...filterCriteria.value,
          nodeLimit: limitCount.value,
        };
        await getInfluencerGraph(finalCriteria);
      }
    };

    // hide popover when scrolling
    const onScroll = () => {
      const scrollElement = document.getElementsByClassName('ant-popover');

      if (scrollElement) {
        for (let ele of scrollElement) {
          ele.style.display = 'none';
        }
      }
    };

    const onViewsChange = (value) => {
      selectViews.value = value;
      if (value === 'grid') {
        selectItems.value = selectGrid;
      } else {
        selectItems.value = selectList;
      }
    };

    const setLoading = (value) => {
      topInfluencerLoading.value = value;
    };

    watch(filterCriteria, () => {
      filterTypeInfluencer.value = [];
      topInfluencerLoading.value = true;
      influencerChartLoading.value = true;
      init();
    });

    onMounted(() => {
      init();
    });

    return {
      isMobileScreen,
      toggleExport,
      activeKey,
      filterCriteria,
      interestChartData,
      typeChartData,
      audienceChartData,
      accountGroupChartData,
      influencersData,
      onSortChange,
      topInfluencerLoading,
      influencerChartLoading,
      measurementBy,
      filterTypeInfluencer,
      onShowChange,
      onScroll,
      setActiveKey,
      limitCount,
      onViewsChange,
      selectItems,
      selectList,
      setLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.influencer-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .blue-text {
    color: #3371ff !important;
  }
  .page-content {
    padding: 0px 30px 20px;
    overflow: auto;
    flex: 1 1 auto;
    .mobile-title {
      margin-top: 23px;
      .page-title {
        font-size: 18px;
      }
    }
    .filter-div {
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
      .influencer-filter {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &.sort-by {
          margin-right: 8px;
        }

        .filter-aspect {
          margin-right: 14px;
          font-size: 14px;
          color: #5a5f7d;
        }
        .type-influencer-container {
          padding: 0;
          position: relative;
          border-radius: 4px;
          background: #ffffff;
          border: none;
          height: 34px;
          .item-influencer {
            position: relative;
            color: #5a5f7d;
            min-width: 160px;
            height: 34px;
            margin: 0;
            border: none;
            @media (max-width: 750px) {
              min-width: 173px;
            }
            @media (max-width: 418px) {
              min-width: 123px;
            }
            @media (max-width: 354px) {
              min-width: 103px;
            }
            &.item-show {
              min-width: 95px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      .filter-div {
        margin-top: unset;
        margin-left: auto;
        .influencer-filter {
          justify-content: center;
          text-align: center;
        }
      }
    }
    @media screen and (max-width: 427px) {
      .filter-div {
        margin-left: auto;
      }
    }
    .section-wrapper {
      margin: 24px 0px;
      align-items: center;
      .section-title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
      }
    }
    &.mobile {
      padding: 15px;
    }
  }
  .source-row {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .user-tag {
    color: #9299b8;
    font-size: 13px;
  }
  .user-menu-wrap {
    display: flex;
    align-items: center;
    padding: 16px;
    color: #adb4d2;
  }
  .action-list {
    border-top: 1px solid #f1f2f6;
    padding-top: 12px;
    padding-bottom: 4px;
    &.no-border {
      border-top: none;
    }
    .title {
      font-size: 14px;
      color: #5a5f7d;
      font-weight: 500;
      text-align: left;
      margin-bottom: 8px;
    }
    .action-row {
      display: flex;
      justify-content: space-between;
      line-height: 16px;
      margin-bottom: 8px;
      .action-title {
        color: #9299b8;
        font-size: 14px;
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
        }
      }
      .action-value {
        color: #5a5f7d;
      }
    }
    &.mobile {
      .title,
      .action-row .action-title {
        font-size: 12px;
      }
    }
  }
  .total-list {
    border: 1px solid #f1f2f6;
    padding: 16px;
    background-color: #f8f9fb;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 16px;
    border-radius: 5px;
    .total-block {
      text-align: center;
      .value-row {
        font-size: 16px;
        font-weight: 500;
        color: #272b41;
        margin-bottom: 6px;
      }
      .unit-row {
        font-size: 13px;
        color: #9299b8;
        text-transform: capitalize;
      }
    }
  }
  .big-item-row {
    column-gap: 24px !important;
    // row-gap: 24px !important;
    flex-wrap: nowrap;
    overflow-x: auto;
    .big-item {
      flex: 1 1 30%;
      min-width: 260px;
      padding: 20px;
      border-radius: 5px;
      background-color: #fff;
      // min-height: 590px;
      text-align: center;
      cursor: pointer;
      .user-photo-wrap {
        user-select: none;
        text-align: center;
        .user-photo {
          width: 96px;
          height: 96px;
          border-radius: 96px;
        }
      }
      .username {
        margin-top: 8px;
        line-height: 20px;
        color: #272b41;
        font-weight: 500;
        font-size: 16px;
      }

      .sentiment-list {
        display: flex;
        justify-content: center;
        column-gap: 30px;
        padding-bottom: 16px;
      }
      .source-row {
        justify-content: center;
      }
    }
    .float-bar {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 100%;
      top: 0;
      left: 0;
      padding-left: 10px;
      padding-right: 10px;
      .user-menu {
        padding: 16px;
      }

      .number-flag {
        border-radius: 0px 0px 10px 10px;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: 700;
        color: #fff;
        &.num-1 {
          background: linear-gradient(180deg, #fada52 0%, #fce67d 100%);
        }
        &.num-2 {
          background: linear-gradient(180deg, #d3d3d3 0%, #e2e2e2 100%);
        }
        &.num-3 {
          background: linear-gradient(180deg, #fba669 0%, #fec293 100%);
        }
      }
    }
  }
  .sentiment-block,
  .sentiment-value {
    display: flex;
    align-items: center;
    .sentiment-circle {
      padding: 3px 0px;
      border-radius: 40px;
      height: 24px;
      width: 24px;
      overflow: hidden;
      text-align: center;
      margin-right: 8px;
      &.positive {
        background-color: rgba(32, 201, 151, 0.15);
        color: #20c997;
      }
      &.neutral {
        background-color: #fff5e2;
        color: #febc3c;
      }
      &.negative {
        background-color: rgba(255, 77, 79, 0.15);
        color: #ff4d4f;
      }
      .value {
        font-size: 14px;
        color: #5a5f7d;
        &.zero {
          color: #adbcd2;
        }
      }
    }
  }
  .sentiment-value {
    .sentiment-circle {
      height: 16px;
      width: 16px;
      margin-right: 4px;
      display: flex;
      padding: 2px;
    }
    .value {
      font-size: 12px;
    }
  }
  .item-table {
    margin-top: 20px;
    width: 100%;
    overflow: auto;
    .table {
      border-collapse: separate;
      border-spacing: 0 16px;
      width: 100%;
      td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      td:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    .head-row {
      th {
        color: #9299b8;
        padding: 0 10px;
        font-weight: 500;
      }
    }
    .item-row {
      cursor: pointer;
      td {
        padding: 20px 10px;
        background-color: #fff;
        color: #5a5f7d;
        vertical-align: top;
        &.blue-text {
          color: #3371ff;
        }
        &.first {
          vertical-align: middle;
        }
      }
      .user-photo-wrap {
        .user-photo {
          width: 64px;
          height: 64px;
          border-radius: 64px;
        }
      }
      .user-info {
        margin-left: 16px;
        max-width: 200px;
        .username {
          margin-bottom: 10px;
          margin-top: 2px;
          line-height: 18px;
          color: #272b41;
          font-weight: 500;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .total-text {
        color: #5a5f7d;
        line-height: 16px;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      .total-value,
      .total-value-mention {
        color: #272b41;
        line-height: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      .horizontal-list {
        display: flex;
        column-gap: 16px;
        .action-row {
          display: flex;
          .action-title {
            color: #9299b8;
          }
          .action-value {
            font-size: 12px;
            margin-left: 8px;
          }
        }
      }
    }
  }
  .mobile-table {
    .table {
      // border-collapse: separate;
      // border-spacing: 0 8px;
      width: 100%;
    }
    .item-row {
      cursor: pointer;
      td {
        padding: 8px 4px 8px 4px;
        background-color: #fff;
        color: #5a5f7d;
        vertical-align: middle;
        border-top: 8px solid;
        border-bottom: 8px solid;
        border-color: #f0f2f5;
        &.middle {
          vertical-align: middle;
        }
      }
      td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-left: 16px;
      }
      td:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        padding-right: 16px;
      }

      &.expand {
        td {
          border-bottom: none;
        }
        td:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 0;
        }
        td:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 0;
        }
      }
      .user-photo-wrap {
        .user-photo {
          width: 32px;
          height: 32px;
          border-radius: 32px;
        }
      }
      .user-info {
        margin-left: 8px;
        .username {
          margin-bottom: 4px;
          margin-top: 2px;
          line-height: 14px;
          color: #272b41;
          font-weight: 500;
          font-size: 12px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 120px;
        }
      }
      .value-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #272b41;
      }
      .unit-text {
        color: #5a5f7d;
        font-size: 9px;
        margin-bottom: 2px;
      }

      .menu-item {
        margin-bottom: 6px;
        padding-top: 16px;
        color: #9299b8;
      }
      .expand-item i {
        color: #9299b8;
      }
    }
    .expand-row {
      td {
        background-color: #fff;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      .action-list {
        font-size: 13px;
      }
    }
    .total-list {
      border: none;
      padding: 16px;
      margin: 0;
      .total-block {
        text-align: center;
        .value-row {
          font-size: 13px;
          font-weight: 500;
          color: #272b41;
          margin-bottom: 4px;
        }
        .unit-row {
          font-size: 12px;
          color: #9299b8;
          text-transform: capitalize;
        }
      }
    }
  }
}
.loading-wrap {
  text-align: center;
  padding: 20px;
}
@media screen and (min-width: 1441px) and (max-width: 1599px) {
  .chart-column-card {
    max-width: 33.33% !important;
    flex: 0 0 33.33% !important;
  }
}
@media screen and (max-width: 1440px) {
  .chart-column-card {
    max-width: 50% !important;
    flex: 0 0 50% !important;
  }
  .account-column-card {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}
@media screen and (max-width: 1024px) {
  .chart-column-card {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}
</style>
<style scoped>
:deep(#sort-selector .ant-select-selector),
:deep(#count-selector .ant-select-selector),
:deep(#alert-review-selector .ant-select-selector),
:deep(#level-select .ant-select-selector) {
  border: none !important;
  height: 34px;
}
:deep(#sort-selector .ant-select-selector .ant-select-selection-item),
:deep(#count-selector .ant-select-selector .ant-select-selection-item),
:deep(#alert-review-selector .ant-select-selector .ant-select-selection-item),
:deep(#level-select .ant-select-selector .ant-select-selection-item) {
  font-weight: 500;
  font-size: 12px;
  color: #5a5f7d;
  text-align: left;
}
.item-influencer.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none;
}
.item-influencer .ant-select-selector,
.item-influencer .ant-select-item-option-content {
  text-transform: capitalize;
}
.item-influencer.ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  padding-left: 0;
}
:deep(
    .item-sort.ant-select-single .ant-select-selector .ant-select-selection-item
  ) {
  padding-left: 0;
}
:deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  border-radius: 4px;
}
:deep(.rc-virtual-list) {
  padding: 0 !important;
}
:deep(.rc-virtual-list-holder) {
  padding: 0 !important;
}
:deep(.rc-virtual-list-holder-inner) {
  padding: 0 !important;
}
:deep(
    .ant-select-dropdown
      .ant-select-item.ant-select-item-option-selected
      .ant-select-item-option-content
  ) {
  font-weight: 400;
  line-height: 16px;
}
:deep(.item-sort.ant-select .ant-select-dropdown .ant-select-item) {
  padding: 8px 10px 8px 24px !important;
}
:deep(.item-show.ant-select .ant-select-dropdown .ant-select-item) {
  padding: 8px 10px 8px 12px !important;
}
:deep(.ant-select-dropdown .ant-select-item .ant-select-item-option-content) {
  text-align: left;
}
</style>
