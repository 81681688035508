<template>
  <a-card id="IndustryAndInterest" :widget="true" :title="'Interest'">
    <div v-if="chartLoading" class="load-wrapper">
      <a-skeleton :loading="chartLoading" active />
    </div>
    <span v-if="!chartLoading" class="influencer-total">
      <span class="total-title">Total:</span>
      <span class="total">{{ influencerCount + ' influencers' }}</span>
    </span>
    <a-row
      v-if="!chartLoading && dataLists.length > 0 && selectedData.length > 0"
      :gutter="25"
      class="industry-card"
    >
      <a-col :xs="24" :md="12" class="chart-column-card">
        <div class="chart-wrapper">
          <EChart
            ref="interestEchart"
            :type="'pie'"
            :data="selectedData"
            :doughnut-chart="false"
            :chart-type="type"
            @chartClick="onChartClick"
          />
        </div>
      </a-col>
      <a-col :xs="24" :md="12" class="industry-list-block">
        <InterestLists
          :listData="dataLists"
          @legendClick="onLegendClick"
        ></InterestLists>
      </a-col>
    </a-row>
    <div
      v-if="!chartLoading && selectedData.length <= 0"
      class="no-data-wrapper"
    >
      <NoResult></NoResult>
    </div>
  </a-card>
</template>

<script>
import { computed, ref, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import numeral from 'numeral';

import EChart from '@/components/Chart/Echart.vue';
import NoResult from '@/components/Error/NoResult.vue';
import InterestLists from '@/components/Lists/InterestLists.vue';

export default {
  name: 'IndustryAndInterest',
  components: {
    NoResult,
    EChart,
    InterestLists,
  },
  props: {
    interestChartData: Array,
    filter: Object,
    loading: Boolean,
  },
  setup(props) {
    const { interestChartData, loading, filter: filterResult } = toRefs(props);
    const { dispatch } = useStore();
    const type = ref('interest');
    const totalCount = ref(0);
    const influencerCount = ref(0);

    const chartLoading = computed(() => loading.value);

    const selectedData = ref([]);
    const dataLists = ref([]);
    const interestEchart = ref(null);

    const getInfluencerInterest = () => {
      let influencerInterestAndIndustry = null;

      if (interestChartData.value && interestChartData.value != null) {
        influencerCount.value = interestChartData.value.relatedInfluencerCount;
        influencerInterestAndIndustry = interestChartData.value.chartData;
      }

      let lists = [];

      if (influencerInterestAndIndustry !== null) {
        // check visible data
        let visibleDataTotal = 0;

        let visibleData = [];

        for (const influencer of influencerInterestAndIndustry) {
          visibleDataTotal += influencer.count;
          visibleData.push({
            name: influencer.categoryName,
            value: influencer.count,
          });
        }

        totalCount.value = visibleDataTotal;

        if (visibleData && visibleData.length) {
          for (let index in visibleData) {
            const color = getColor(false, index);
            let valueAsPercent = convertValueToPercent(
              visibleData[index].value,
              visibleDataTotal
            );
            lists.push({
              name: visibleData[index].name,
              value: visibleData[index].value,
              valueAsPercent: valueAsPercent,
              isSelected: false,
              itemStyle: {
                color: color,
              },
            });
          }
        }
        dataLists.value = lists.sort((a, b) =>
          parseFloat(a.valueAsPercent) > parseFloat(b.valueAsPercent)
            ? -1
            : parseFloat(b.valueAsPercent) > parseFloat(a.valueAsPercent)
            ? 1
            : 0
        );
      }

      selectedData.value = lists;
    };

    const getColor = (same, index) => {
      const defaultColors = [
        '#2C99FF',
        '#FF87B7',
        '#4DD4AC',
        '#5F63F2',
        '#FFBD59',
        '#F44771',
        '#353E6C',
        '#7961AC',
        '#B9C0DE',
        '#D3D3D3',
      ];
      if (index <= 10) {
        return defaultColors[index];
      } else {
        let lastColor;
        var rgb = [];
        if (same && lastColor) {
          rgb = lastColor;
        } else {
          rgb = [Math.random() * 256, Math.random() * 256, Math.random() * 256];
          var mix = [rgb[0] / 50, rgb[1] / 50, rgb[2] / 50]; //51 => 255/5
          var mixedrgb = [
            rgb[0] + mix[0],
            rgb[1] + mix[1],
            rgb[2] + mix[2],
          ].map(function (x) {
            return Math.round(x);
          });
          lastColor = rgb;
          return 'rgb(' + mixedrgb.join(',') + ')';
        }
      }
    };

    const convertValueToPercent = (value, total) => {
      return numeral((value / total) * 100).format('0.00');
    };

    // Modal
    const onChartClick = (value) => {
      if (value) {
        const type = value.data.name;
        const userId = [];
        let excludeUserId = [];
        const { influencers, peopleInfo } = interestChartData.value;
        for (let peopleObj of influencers) {
          const info = peopleInfo[peopleObj.id];
          if (info.interests.length !== 0) {
            for (let interest of info.interests) {
              if (interest.interest_name === type) {
                userId.push(peopleObj.id);
              } else {
                excludeUserId.push(peopleObj.id);
              }
            }
          }
        }

        if (excludeUserId.length > 0) {
          if (userId.length === 0) {
            userId.push('others');
          } else if (userId.length > 0) {
            excludeUserId = [];
          }
        }


        const newFilter = JSON.parse(JSON.stringify(filterResult.value));
        let f = {
          payload: {
            title: `Messages from "${type}"`,
          },
          criteria: {
            ...newFilter,
            userId,
            excludeUserId,
            sort: {
              direction: 'desc',
              type: 'engagement_score',
            },
            highlight: {
              enable: true,
            },
          },
          type: 'influencer',
        };

        dispatch('message/showMessageModal', f);
      }
    };

    const onLegendClick = (value) => {
      interestEchart.value.eChart.dispatchAction({
        type: 'legendToggleSelect',
        // legend name
        name: value.name,
      });
    };

    const init = async () => {
      await getInfluencerInterest();
    };

    watch(
      () => filterResult.value,
      () => {
        init();
      }
    );

    watch(
      () => interestChartData.value,
      () => {
        init();
      }
    );

    return {
      chartLoading,
      selectedData,
      onChartClick,
      type,
      dataLists,
      interestEchart,
      onLegendClick,
      totalCount,
      influencerCount,
    };
  },
};
</script>

<style lang="scss">
@import '../../../config/theme/colors.json';

#IndustryAndInterest {
  .mode-dropdown-mobile {
    width: 104px;
    height: 100px;
  }

  .button-primary-light {
    /* TODO Refactor to outline btn */
    background: #2c99ff15;
    border-width: 0px;
    color: $primary-color;

    &:focus {
      background: #2c99ff15;
      border-width: 0px;
      color: $primary-color;
    }
  }

  .industry-representation-selector-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 12px 0;
  }

  @media screen and (max-width: 560px) {
    .industry-representation-selector-wrapper {
      margin-bottom: 0;
      padding: 15px 0;
    }
  }

  .industry-accumulation-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    row-gap: 16px;
    column-gap: 16px;

    .industry-mode-toggle {
      .ant-dropdown-trigger {
        line-height: 30px;
        margin: 0;
      }
    }
  }

  .loading-wrapper,
  .no-data-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }

  .ant-card-head-wrapper .ant-card-extra {
    padding: 0px !important;
    display: block;
  }

  @media screen and (max-width: 560px) {
    .ant-card-head-wrapper .ant-card-extra {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      column-gap: 12px;
    }
  }

  .ant-card-body {
    padding: 25px !important;
  }

  .ant-dropdown {
    .ant-dropdown-menu {
      min-width: 200px;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 0;
    }
  }

  .chart-type {
    width: fit-content;
    margin-left: auto;
    padding: 15px 0;
    line-height: 1.4;
  }

  @media screen and (max-width: 560px) {
    .chart-type {
      margin-left: 0;
      justify-content: flex-end;
    }
  }

  .more-menu {
    color: #9299b8;
  }

  .mode-mobile {
    width: 104px;
    height: 100px;
    box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.2);
    border-radius: 6px;
  }

  .influencer-total {
    font-size: 12px;
    line-height: 16px;

    .total-title {
      color: #9299b8;
      padding-right: 4px;
    }

    .total {
      color: #5a5f7d;
    }
  }

  .industry-card {
    // Chart
    .chart-column-card {
      .chart-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media only screen and (max-width: 766px) {
          margin-bottom: 24px;
        }

        x-vue-echarts {
          display: flex !important;
          height: 400px !important;
          @media only screen and (max-width: 766px) {
            height: 280px !important;
          }
        }
      }
    }

    .industry-list-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 !important;
    }
  }
}
</style>
